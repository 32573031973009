import globals from "../globals.js"
export default {
    data() {
        return {

        }
    },
    methods:{
        addProductRole(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/products/product_role", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getListProductRole(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/products/product_role/list", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getProductRoleById(id) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.get(globals.AUTH_SERVICE + "/products/product_role/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        updateProductRoleById(params, id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.patch(globals.AUTH_SERVICE + `/products/product_role/${id}`, params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        deleteProductRoleById(id) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.delete(globals.AUTH_SERVICE + "/products/product_role/" + id)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        withdrawSubscriptionById(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/company/products/withdrawal", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        getEmployeesRequest(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/company/products/employee/requests", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    reject(error.response.data.reason)
                }
            })
        },
        requestProductAccess(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/employees/product/request", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        withdrawProductAccess(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/employees/product/withdrawal", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        rejectRequestAccess(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/employees/product/request/reject", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        acceptRequestAccess(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post(globals.AUTH_SERVICE + "/employees/product/request/accept", params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.reason)
                        this.warning_msg = response.data.reason
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    }
                }
                catch(error){
                    this.warning_msg = error.response.data.reason
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 3000);
                }
            })
        },
        // addEmpToProduct(params) {
        //     return new Promise(async (resolve, reject) => {
        //         let response = await this.$http.post(globals.AUTH_SERVICE + `/employees/add/product`, params)
        //         if (response.data.status_id == 1) {
        //             resolve(response.data)
        //         } else {
        //             reject(response.data.reason)
        //         }
        //     })
        // },
    }
}
