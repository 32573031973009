<template>
    <div class="create-company-container w-100 mt-6">
        <div class="create-company-header d-flex align-items-center justify-content-between">
            <span class="fs-14 text-white">Request Manager</span>
            <span class="pointer mr-2" @click="toggleRequestManger">
                <i v-if="request_manager" class="icon-chevron-double-up text-white"
                    style="font-size: 20px"></i>
                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
            </span>
        </div>
        <div class="mb-4 collapse show px-5" id="request_manager" v-if="request_manager">
            <div class="pt-5">
                <div class="px-2 mb-3">
                    <span class="fs-14 text-secondary">Product</span>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <multiselect class="diginew-multiselect" style="" @select="productSelected" label="product_name" name="product_name" :loading="product_loading" @open="getProducts" :show-labels="false" :searchable="true" :options="product_options" v-model="product_name" placeholder="Select Product">
                            <template slot="noOptions">
                                <div class="text-secondary text-center fw-600">List is Empty</div>
                            </template>
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
            <div class="d-flex align-items-center justify-content-center w-100 pt-8" v-else-if="!ajax_call_in_progress && employees_req_data.length == 0">
                <span>No Products Requests Found</span>
            </div>
            <div v-else class="row pl-2" style="flex-wrap:wrap;">
                <div v-for="(products, products_index) in employees_req_data" :key="products_index+'products_index_index'" class="col-4 mt-12 d-flex justify-content-center">
                    <div class="w-280px w-100 h-100" style="box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);border-bottom-left-radius: 5px;border-bottom-right-radius:5px">
                        <div class="px-2" style="background: url('/static/images/Rectangle 19382@3x.png') no-repeat;background-size:100% 100%;height:300px;">
                            <div class="dotted_line">
                                <span class="" style="border-right:4px dotted #fff; opacity: 0; visibility: hidden;" role="button"></span>
                            </div>
                            <div class="d-flex flex-column w-100 user_details">
                                <div class="ml-auto mr-auto">
                                    <img 
                                    :class="{'profile_pic_super_admin': products.user_access == 'super admin' || 'Super Admin', 'profile_pic_admin': products.user_access == 'admin' || 'Admin', 'profile_pic_products': products.user_access == 'products' || 'products'}"
                                    :src="products.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
                                        width="135" height="135" alt="">
                                </div>
                                <span class="text-white text-center fw-600 fs-16 text-capitalize" style="margin-top:2.2rem">{{products.full_name}}</span>
                                <button style="margin:0.5rem 15px 0px" class="text-capitalize"
                                :class="{'profile_button_super_admin': products.user_access == 'super admin' || 'Super Admin', 'profile_button_admin': products.user_access == 'admin' || 'Admin', 'profile_button_products': products.user_access == 'products' || 'products'}"
                                >{{products.user_access.replace("_","")}}</button>
                            </div>
                        </div>
                        <div class="px-3">
                            <div class="e-tab">
                                <span class="py-2 fs-12">Email</span>
                                <span class="py-2 fs-12 email-ellipses">{{products.email}}</span>
                            </div>
                            <div class="e-tab" style="border-top:1px solid #000;">
                                <span class="py-2 fs-12">Tel</span>
                                <span class="py-2 fs-12">{{products.phone_number.phone_number || "-"}}</span>
                            </div>
                            <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="text-center d-flex align-items-center justify-content-center" style="padding:20px 25px 25px 25px">
                                <button class="btn btn-danger" style="border-radius: 5px !important;" @click="rejectRequest(products.id)" >Reject</button>
                                <button class="btn btn-new-success ml-2" @click="acceptRequest(products.id)" >Accept</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
    import companies from '../mixins/companies';
    import SubscriptionModal from './SubscriptionModal.vue'
    import { SweetModal } from 'sweet-modal-vue';
    import {HalfCircleSpinner} from 'epic-spinners';
    import product from '../mixins/product';
import globals from '../globals';
    export default {
        mixins: [companies, product],
        props: ['comp_data'],
        data() {
            return {
                request_manager: true,
                search_key: '',
                ajax_call_in_progress: false,
                employees_req_data: [],
                product_name: '',
                product_options: [],
                product_loading: false,
                product_id: '',
                subscription_text: '',
                error_text: '',
                warning_msg: '',
                success_msg: '',
                skip: 0,
                limit: 10,
            };
        },
        components: {
            SubscriptionModal,
            HalfCircleSpinner,
            SweetModal
        },
        methods: {
            toggleRequestManger(){
                this.request_manager = !this.request_manager
            },
            productSelected(data){
                this.product_id = data.id;
                setTimeout(() => {
                    this.getEmployeesReq('product_id');
                }, 500);
            },
            async rejectRequest(id) {
                // try {
                    let params = {
                        id: id
                    }
                    await this.$http.post(globals.AUTH_SERVICE + "/employees/product/request/reject", params).then((response)=>{
                        if(response.data.status_id == 1) {
                            this.success_msg = response.data.message
                            this.$refs.success_modal.open();
                            setTimeout(() => {
                                this.$refs.success_modal.close();
                                this.getEmployeesRequestList(this.$route.params.company_id, 'product_id');
                            }, 2000);
                        }
                    }).catch((error)=>{
                        // console.log(error);
                        this.warning_msg = error.response.data.reason;
                        this.$refs.warning_modal.open();
                    })
                // }
                // catch(err) {
                //     console.log(err);
                    // this.warning_msg = err;
                    // this.$refs.warning_modal.open();
                // }
            },
            async acceptRequest(id) {
                let params = {
                        id: id
                    }
                    await this.$http.post(globals.AUTH_SERVICE + "/employees/product/request/accept", params).then((response)=>{
                        if(response.data.status_id == 1) {
                            this.success_msg = response.data.message
                            this.$refs.success_modal.open();
                            setTimeout(() => {
                                this.$refs.success_modal.close();
                                this.getEmployeesRequestList(this.$route.params.company_id, 'product_id');
                            }, 2000);
                        }
                    }).catch((error)=>{
                        // console.log(error);
                        this.warning_msg = error.response.data.reason;
                        this.$refs.warning_modal.open();
                    })
            },
            async getEmployeesRequestList(comp_id, type) {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        company_id: comp_id,
                        product_id: type == 'product_id' ? this.product_id : '',
                        skip: this.skip,
                        limit: this.limit,
                    };
                    let response = await this.getEmployeesRequest(params);
                    if(response.status_id == 1) {
                        this.employees_req_data = response.response;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            },
            async getProductsList(comp_id) {
                this.product_loading = true;
                try {
                    let params = {
                        company_id: comp_id,
                        search_key: this.search_key,
                        skip: this.skip,
                        limit: this.limit
                    };
                    let response = await this.getAllProducts(params);
                    if(response.status_id == 1) {
                        this.product_options = response.subscribed_products;
                        // this.product_options.forEach((product, p_index)=>{
                        //     if((this.loggedInUser.industry_type != 'Accommodation and food services') && product.product_name == 'DigiPOS') {
                        //         this.product_options.splice(p_index, 1)
                        //     }
                        // })
                    }
                    this.product_loading = false;
                }
                catch(err) {
                    this.product_loading = false;
                }
            },
            getEmployeesReq(type) {
                this.getEmployeesRequestList(this.$route.params.company_id, type);
            },
            getProducts() {
                this.getProductsList(this.$route.params.company_id);
            }
        },
        mounted() {
            this.getEmployeesReq('company_id');
            // EventBus.$on('searchProds', (search_key) => {
            //     this.search_key = search_key;
            //     this.getProducts();
            // })
        },
        computed: {
			loggedInUser() {
				return this.$store.state.loggedInUser;
			},
		},
    };
</script>

<style scoped>
    .product-access-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .w-150pxcenter {
        max-width: 150px;
        margin: 0 auto;
    }

    .subs-btn {
        background-color: #128807;
        color: #fff;
        border-radius: 5px;
    }

    .access-tab{
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.41);
    }

    .arrow_up{
        transform: rotate(180deg);
    }
    .arrow_down{
        transform: rotate(0deg);
    }
    .product-access-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .profile_button {
        cursor: pointer;
        background-color: #ff268a;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
    }

    .w-280px {
        max-width: 265px;
        min-width: 140px;
    }

    .add_access_input {
        border-bottom: 1px solid grey;
    }

    .add_access_input:focus {
        border-bottom: 1px solid #0d84f2;
    }

    .list-group-item {
        border-bottom: 1px solid #cce3fc
    }

    .pl-5px {
        padding-left: 5px;
    }

    .pr-5px {
        padding-left: 5px;
    }
    
    .list-item-content{
        padding-left:10px;
    }

    .dropdown-profiles{
        left:-30px !important;  
    }

    .profile_button_super_admin {
        cursor: pointer;
        background-color: #ff268a;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline:none;
    }

    .profile_button_admin {
        cursor: pointer;
        background-color: #0d84f2;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline:none;
    }

    .profile_button_employee {
        cursor: pointer;
        background-color: #50bcff;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline:none;
    }

    .profile_pic_super_admin{
        max-width:100%;
		height: 135px !important;
        border-radius: 50%;
		border: 5px solid transparent;
    	outline: 2px solid orange;
    }

    .profile_pic_admin{
        max-width:100%;
		height: 135px !important;
        border-radius: 50%;
		border: 5px solid transparent;
    	outline: 2px solid #1a9347;
    }

    .profile_pic_employee{
        max-width:100%;
		height: 135px !important;
        border-radius: 50%;
		border: 5px solid transparent;
    	outline: 2px solid #1a9347;
    }

    .rm-access-btn {
        cursor: pointer;
        padding: 10px;
        background-color: #e82828;
        color: #fff;
        width: 100%;
        border-radius: 5px;
        outline:none;
    }

    .dotted_line{
        position:relative;
        float:right;
        top:15px;
        right:10px;
    }

    .user_details{
        position:relative;
        top:15px;
    }

    .e-tab {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width:991.5px) {
        .e-tab {
            flex-direction: row;
        }
    }
    .email-ellipses {
        margin-left: 20px;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
</style>